<template lang="pug">
  v-container(grid-list-md)
    h1.section-title Данные моего ребенка использованы для зачислений?

    //- v-alert.white--text(
    //-   color="warning"
    //-   type="warning"
    //- ) Функционал обновляется. Будет доступен с 1 сентября 2022 года

    v-stepper(v-model="stepper" flat vertical)
      v-stepper-step(
        :step="1"
        :complete="stepper > 1"
        :class="{ 'cursor-pointer': stepper > 1 }"
        @click="stepClick(1)"
      ) Выбор региона
      v-stepper-content(:step="1")
        step-select-region(
          :certificate="certificate"
          @next="stepper += 1"
        )

      v-stepper-step(
        :step="2"
        :complete="stepper > 2"
        :class="{ 'cursor-pointer': stepper > 2 }"
        @click="stepClick(2)"
      ) Заполнение данных ребенка
      v-stepper-content(:step="2")
        step-certificate-data(
          :certificate="certificate"
          :loading="loading"
          @prev="stepper -= 1"
          @submit="checkEnrollments"
        )

      v-stepper-step(
        :step="3"
        :complete="stepper > 3"
        :class="{ 'cursor-pointer': stepper > 3 }"
        @click="stepClick(3)"
      ) Мои зачисления
      v-stepper-content(:step="3")
        step-enrollments(
          :certificate="certificate"
          :enrollments="enrollments"
          :loading="loading"
          :token="token"
          @prev="stepper -= 1"
          @next="stepper += 1"
          @submit="checkEnrollments"
          @fake="haveFakeEnrollments"
        )

      v-stepper-step(
        v-show="stepper >= 4 && fakeStatus"
        :step="4"
        :complete="stepper > 4"
        :class="{ 'cursor-pointer': stepper > 4 }"
        @click="stepClick(4)"
      ) И как с этим быть?
      v-stepper-content(v-show="stepper >= 4 && fakeStatus" :step="4")
        step-subscribe-text(
          @prev="stepper -= 1"
          @subscribe="goToSubscribe"
          @complete="stepper = 6"
        )

      v-stepper-step(
        v-show="subscribeAppeal"
        :step="5"
        :complete="stepper > 5"
        :class="{ 'cursor-pointer': stepper > 5 }"
        @click="stepClick(5)"
      ) Подписаться под обращением
      v-stepper-content(v-show="subscribeAppeal" :step="5")
        step-parent-data(
          :token="token"
          :certificate="certificate"
          @prev="stepper -= 1"
          @next="stepper += 1"
        )

      v-stepper-step(
        v-show="stepper === 6"
        :step="6"
        :complete="true"
        :class="{ 'cursor-pointer': stepper > 5 }"
        @click="stepClick(6)"
      ) Завершение
      v-stepper-content(:step="6" v-show="stepper === 6")
        step-complete(
          v-if="stepper === 6"
          :fakeStatus="fakeStatus"
          :subscribeAppeal="subscribeAppeal"
          :region="certificate.region"
          :token="token"
        )

</template>

<script>
import API from '@/services/check-enrollments';

import StepSelectRegion from './components/StepSelectRegion';
import StepCertificateData from './components/StepCertificateData';
import StepEnrollments from './components/StepEnrollments';
import StepSubscribeText from './components/StepSubscribeMessage';
import StepParentData from './components/StepParentData';
import StepComplete from './components/StepComplete';

export default {
  name: 'CheckEnrollments',
  components: {
    StepComplete,
    StepParentData,
    StepSubscribeText,
    StepEnrollments,
    StepCertificateData,
    StepSelectRegion,
  },
  data: () => ({
    stepper: 1,
    certificate: {},
    enrollments: [],
    token: null,
    subscribeAppeal: false,
    fakeStatus: null,
    loading: false,
  }),
  watch: {
    stepper(val) {
      if (val < 5) {
        this.subscribeAppeal = false;
      }
    },
  },
  methods: {
    stepClick(step) {
      if (this.stepper > step) {
        this.stepper = step;
      }
    },
    checkEnrollments() {
      this.loading = true;

      const fillDocument = this.certificate.number?.length === 4;
      this.$set(this.certificate, 'fillDocument', fillDocument);
      // this.certificate.birthday = this.certificate.birthday.split('-').reverse().join('.');
      this.certificate.operator_id = this.certificate.region?.operator_id;

      this.certificate.soname = `${this.certificate.soname[0].toUpperCase()}${this.certificate.soname.slice(1).toLowerCase()}`;
      this.certificate.name = `${this.certificate.name[0].toUpperCase()}${this.certificate.name.slice(1).toLowerCase()}`;
      if (this.certificate.phname) {
        this.certificate.phname = `${this.certificate.phname[0].toUpperCase()}${this.certificate.phname.slice(1).toLowerCase()}`;
      }

      let request;
      if (this.certificate?.region?.reg_id === 'YAN') {
        request = API.getEnrollmentsYanao;
      } else if (this.certificate?.region?.reg_id === 'VOR') {
        request = API.getEnrollmentsVor;
      } else {
        request = API.getEnrollments;
      }
      // const request = this.certificate?.region?.reg_id === 'YAN' ? API.getEnrollmentsYanao : API.getEnrollments;

      this.$rHandler(
        request(this.certificate),
        ({ data }) => {
          this.enrollments = data.data.record_marks;
          this.token = data.data.confirm_token;
          this.certificate.count = Number(data.data.count);
          this.stepper = this.certificate.count ? 3 : 2;
        },
      ).finally(() => { this.loading = false; });
    },
    haveFakeEnrollments(fakeStatus) {
      this.fakeStatus = fakeStatus;
      if (fakeStatus === false) {
        this.stepper = 6;
      }
    },
    goToSubscribe() {
      this.subscribeAppeal = true;
      this.stepper += 1;
    },
  },
};
</script>

<style scoped>

  .v-stepper__step {
    padding-left: 15px;
  }

  .v-stepper--vertical .v-stepper__content {
    margin-left: 25px;
  }

  @media (max-width: 600px) {
    .v-stepper--vertical .v-stepper__content {
      margin-left: 10px;
      padding-right: 50px;
    }

    .v-stepper__step {
      padding-left: 0;
    }

    ::v-deep .v-alert__wrapper i {
      display: none;
    }

    ::v-deep .v-btn:not(.btn__socials) {
      width: 100%;
      margin-right: 0!important;
      margin-bottom: 4px;
    }
  }

</style>
