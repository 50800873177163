<template lang="pug">
  div
    v-alert(
      v-if="loading || (enrollments.length && !certificate.fillDocument && visibleDocument)"
      type="info"
      color="primary"
      outlined
    )
      h4(v-if="certificate.count > 1") К сожалению, в региональный навигатор внесена информация о более чем одном ребенке с
        |  указанным ФИО и датой рождения. То есть, ответственные лица убеждены, что у Вашего ребенка есть полные тёзки,
        |  родившийся с ним в один день. Поздравляем! Конечно, это может быть просто хитрость лиц, вносивших сведения в
        |  региональный навигатор. Для уточнения запроса, просим Вас указать последние 4 цифры номера документа ребенка.

      h4(v-else) Если вы считаете, что ниже представлены зачисления другого ребенка (полного тёзки), для уточнения проверки
        |  введите последние 4 цифры номера документа Вашего ребенка.

      v-text-field(
        v-model="certificate.number"
        label="Последние 4 цифры номера документа ребенка (свидетельство о рождении или паспорт)"
        counter="4"
        type="number"
      )

      v-btn(
        color="primary"
        :loading="loading"
        :disabled="!(certificate.number && certificate.number.length === 4)"
        @click="$emit('submit')"
      ) Проверить

    v-alert(
      v-if="haveFakeEnrollments"
      type="warning"
      color="secondary"
      outlined
    ) Пожалуйста, отметьте те зачисления, которых в действительности не было

    v-layout(row)
      v-flex(v-for="enrollment in enrollments" sm12 md6 :key="enrollment.id")
        enrollment-item(
          v-model="fakeEnrollments"
          :enrollment="enrollment"
          :region="certificate.region"
          :have-fake="haveFakeEnrollments"
        )

      v-flex(v-if="certificate.count === 1 && !enrollments.length" sm12)
        v-alert.text-center.py-5(
          color="primary"
          outlined
        ) У ребенка, сведения о котором указаны Вами на предыдущем шаге не было зачислений после 1 января 2021 года.
      v-flex(v-if="certificate.count > 1 && !visibleDocument" sm12)
        v-alert.text-center.py-5(
          color="primary"
          outlined
        ) К сожалению, в региональный навигатор внесена информация о более чем одном ребенке с указанным ФИО и датой рождения.
          |  То есть, ответственные лица убеждены, что у Вашего ребенка есть полный тёзка, родившийся с ним в один день.
          |  Поздравляем! Конечно, это может быть просто хитрость лиц, вносивших сведения в региональный навигатор.
          |  Но однозначный результат мы все равно не можем вывести, простите(

    .d-flex.justify-end.mt-3.flex-wrap
      v-btn.mr-auto.mt-1.text-wrap(
        v-if="enrollments.length"
        color="success"
        outlined
        depressed
        @click="haveFakeEnrollments = false"
      )
        span.hidden-sm-and-down.success--text Все зачисления действительно справедливы
        span.hidden-md-and-up.success--text  Зачисления справедливы

      v-btn.mr-2.mt-1.order-first.order-sm-0(
        depressed
        @click="$emit('prev')"
      ) Назад
      v-btn.mt-1(
        v-if="!haveFakeEnrollments && enrollments.length"
        color="primary"
        depressed
        @click="haveFakeEnrollments = true"
      ) Вижу фейковые зачисления
      v-btn.mt-1(
        v-if="haveFakeEnrollments"
        color="primary"
        depressed
        :disabled="!fakeEnrollments.length"
        @click="sendFakeEnrollments"
      ) Отметить как фейковые

</template>

<script>
import API from '@/services/check-enrollments';
import EnrollmentItem from './EnrollmentItem';

const OUR_AIS = 3;

export default {
  name: 'StepEnrollments',
  components: { EnrollmentItem },
  props: {
    certificate: { type: Object, required: true },
    enrollments: { type: Array, required: true },
    token: { type: String, default: null },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    haveFakeEnrollments: null,
    fakeEnrollments: [],
  }),
  computed: {
    visibleDocument() {
      return OUR_AIS === this.certificate.region?.certificate_source;
    },
  },
  watch: {
    haveFakeEnrollments(val) {
      if (val) this.fakeEnrollments = [];
      this.$emit('fake', val);
    },
  },
  methods: {
    sendFakeEnrollments() {
      const params = {
        confirm_token: this.token,
        record_marks: this.fakeEnrollments,
      };
      const request = this.certificate?.region?.reg_id === 'YAN' ? API.saveEnrollmentsYanao : API.saveEnrollments;

      this.$rHandler(request(params));
      this.$emit('next');
    },
  },
};
</script>
