import axios from 'axios';
import { apiServiceV2 } from '@/api/api.service';

const NAMESPACE = '/enrollment-verification';
const NAMESPACE_YANAO = 'https://pfdo.yanao.ru/yanao-api/v2/enrollment-verification';
const NAMESPACE_VOR = 'https://pfdoapi.govvrn.ru/v2/enrollment-verification';

export default {

  getEnrollments(params) {
    return apiServiceV2.post(NAMESPACE, params);
  },

  saveEnrollments(params) {
    return apiServiceV2.post(`${NAMESPACE}/verify`, params);
  },

  confirmEmail(token) {
    return apiServiceV2.post(`${NAMESPACE}/confirm-email/${token}`);
  },

  getCheckedStatistic(token) {
    return apiServiceV2.get(`${NAMESPACE}/statistics/${token}`);
  },

  getEnrollmentsYanao(params) {
    return axios.post(NAMESPACE_YANAO, params);
  },

  saveEnrollmentsYanao(params) {
    return axios.post(`${NAMESPACE_YANAO}/verify`, params);
  },

  confirmEmailYanao(token) {
    return axios.post(`${NAMESPACE_YANAO}/confirm-email/${token}`);
  },

  getCheckedStatisticYanao(token) {
    return axios.get(`${NAMESPACE_YANAO}/statistics/${token}`);
  },

  getEnrollmentsVor(params) {
    return axios.post(NAMESPACE_VOR, params);
  },

};
