<template lang="pug">
  v-checkbox(
    v-model="inputValue"
    v-bind="$attrs"
  )
    template(v-slot:label)
      p.mb-0 Предоставляю согласие на
        router-link.ml-1.inherit--text__size(
          :to="{ name: 'information-policy' }"
          target="_blank"
          @click.native.stop
        ) обработку внесенных выше персональных данных

</template>

<script>
export default {
  name: 'PolicyCheckbox',
  props: {
    value: { type: Boolean, default: false },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
