<template lang="pug">
  div
    v-alert(
      type="success"
      outlined
    )
      span.success--text {{ completeText }}

    v-alert.alert__share(outlined color="lesslight")
      p.text-justify В регионе
        span.secondary--text  {{ region.title }}
        |  родителями на предмет использования персональных данных для создания фиктивных зачислений проверено уже
        span.secondary--text  {{ regionStatistic.certificates_verified_count || 0 }}
        |  детей. Из них
        span.secondary--text  {{ regionStatistic.certificates_with_fakes_count || 0 }}
        |  имели в навигаторе зачисления, о которых родители не догадывались. То есть,
        span.secondary--text  {{ getPercent() }}%
        | !
        br
        | А вдруг данные Вашего ребенка используются в целях "отчитаться, что все желающие получают бесплатное дополнительное
        |  образование в организациях", проверьте по ссылке
        a(href="https://pfdo.ru/enrollments")  https://pfdo.ru/enrollments
        | .`


      b.d-block.d-sm-inline.primary--text.mb-3 Поделитесь результатом в родительских чатах:
      ShareNetwork.ml-1(
        v-for="network in networks"
        :network="network"
        url="https://pfdo.ru/enrollments"
        :title="shareDescription"
        :popup="{width: 600, height: 500}"
        media="https://interactive-examples.mdn.mozilla.net/media/cc0-images/grapefruit-slice-332-332.jpg"
        :key="network"
      )
        v-btn.btn__socials(text fab small)
          v-img(
            :src="require(`@/assets/icons/${network}.png`)"
            max-width="26px"
            contain
          )

    v-btn(to="/" color="primary" depressed)
      span.hidden-sm-and-down Вернуться на главный экран
      span.hidden-md-and-up  главный экран

</template>

<script>
import API from '@/services/check-enrollments';

export default {
  name: 'StepComplete',
  props: {
    fakeStatus: { type: Boolean, default: false },
    subscribeAppeal: { type: Boolean, required: true },
    region: { type: Object, default: () => ({}) },
    token: { type: String, required: true },
  },
  data: () => ({
    networks: [
      'WhatsApp',
      'Telegram',
      'Viber',
      // 'Twitter',
      // 'VK',
      // 'Facebook',
    ],
    regionStatistic: {},
  }),
  computed: {
    completeText() {
      if (this.fakeStatus === false) {
        return 'Спасибо большое за участие в проверке! Мы рады, что Вас не коснулась проблема фиктивных зачислений.';
      }
      if (this.subscribeAppeal) {
        return 'Спасибо Вам за Вашу гражданскую позицию. На указанную электронную почту скоро придет письмо с адреса'
          + ' noreply@pfdo.ru, в котором будет размещена ссылка для верификации почты, пожалуйста, обязательно пройдите по ней.';
      }
      return 'Понимаем Вашу неготовность подписаться под обращением, надеемся, что все равно наберется достаточное число'
        + ' подписей. Спасибо за участие!';
    },
    shareDescription() {
      // eslint-disable-next-line
      return `В регионе ${this.region.title} родителями на предмет использования персональных данных для создания фиктивных зачислений проверено уже ${this.regionStatistic.certificates_verified_count || 0} детей. Из них ${this.regionStatistic.certificates_with_fakes_count || 0} имели в навигаторе зачисления, о которых родители не догадывались. То есть, ${this.getPercent()}%!\n А вдруг данные Вашего ребенка используются в целях "отчитаться, что все желающие получают бесплатное дополнительное образование в организациях", проверьте по ссылке pfdo.ru/enrollments.`;
    },
  },
  created() {
    this.getRegionStatistic();
  },
  methods: {
    getRegionStatistic() {
      const request = this.region?.reg_id === 'YAN' ? API.getCheckedStatisticYanao : API.getCheckedStatistic;

      this.$rHandler(
        request(this.token),
        ({ data }) => {
          if (data.data.length) {
            [this.regionStatistic] = data.data;
          }
        },
        (err) => err,
      );
    },
    getPercent() {
      const verified = Number(this.regionStatistic.certificates_verified_count) || 0;
      const fakes = Number(this.regionStatistic.certificates_with_fakes_count) || 0;
      return Math.floor((100 / verified) * fakes) || 0;
    },
  },
};
</script>

<style scoped lang="scss">

  .alert__share {
    white-space: pre-line;

    a {
      font-size: inherit;
    }
  }

</style>
