<template lang="pug">
  v-menu(
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  )
    template(
      v-slot:activator="{ on }"
    )
      v-text-field(
        v-bind:value="formattedDate"
        v-on:input="onInput"
        :label="label"
        :rules="rules"
        prepend-icon="event"
        :readonly="!canType"
        :clearable="clearable"
        v-on="on"
        v-bind="$attrs"
        :class="{ 'navigator--style' : navigatorDesign }"
        :solo="navigatorDesign"
      )
    v-date-picker(
      v-bind:value="parsedDate"
      @input="onChange"
      locale="ru-ru"
      no-title
      :min="min"
      :max="max"
      first-day-of-week="1"
    )

</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: { type: String, required: false, default: null },
    min: { type: String, required: false, default: '' },
    max: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    rules: { type: Array, required: false, default: () => ([]) },
    canType: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, required: false, default: true },
    defaultValue: { type: String, required: false, default: null },
    navigatorDesign: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    parsedDate() {
      if (!this.value) return this.defaultValue;
      return this.value.split('.').reverse().join('-');
    },
    formattedDate() {
      if (!this.value) return null;
      return this.value.split('-').reverse().join('.');
    },
    today() {
      const now = new Date();
      let month = `${now.getMonth() + 1}`;
      month = month.length === 1 ? `0${month}` : month;
      return `${now.getFullYear()}-${month}-${now.getDate()}`;
    },
  },
  methods: {
    onInput(value) {
      if (!value) return value;
      const inputedDate = value.replaceAll('.', '');
      if (this.canType && inputedDate.length === 8) {
        const day = inputedDate.substring(0, 2);
        const month = inputedDate.substring(2, 4);
        const year = inputedDate.substring(4, 8);
        const date = `${year}-${month}-${day}`;
        const newDate = new Date(date);

        if ((newDate instanceof Date && !Number.isNaN(+newDate))) {
          this.$emit('input', date);
          this.$emit('change', date);
          setTimeout(() => { this.menu = false; }, 500);
        } else {
          this.$emit('input', this.today);
          this.$emit('change', this.today);
          this.$toasted.error('Введен не правильный формат даты', { duration: 3000, icon: 'error' });
        }
      }
      return value;
    },
    onChange(value) {
      this.$emit('input', value);
      this.$emit('change', value);
      this.menu = false;
    },
  },
};
</script>
