<template lang="pug">
  v-form(ref="form")
    v-layout(row)
      v-flex(sm12 md6)
        v-text-field(
          v-model.trim="certificate.soname"
          label="Фамилия ребенка*"
          type="text"
          :rules="[$rules.required]"
        )

      v-flex(sm12 md6)
        v-text-field(
          v-model.trim="certificate.name"
          label="Имя ребенка*"
          :rules="[$rules.required]"
        )

      v-flex(sm12 md6)
        v-text-field(
          v-model.trim="certificate.phname"
          label="Отчество ребенка (при наличии)"
        )

      //- v-flex(sm12 md6)
      //-   date-picker(
      //-     label="Дата рождения ребенка*"
      //-     v-model="certificate.birthday"
      //-     :rules="[$rules.required]"
      //-     can-type
      //-   )

    v-text-field(
      v-model="certificate.number"
      label="Последние 4 цифры номера документа ребенка (свидетельство о рождении или паспорт)"
      counter="4"
      :rules="[$rules.maxSymbols(4), $rules.required]"
      type="number"
    )

    policy-checkbox(:rules="[$rules.required]")

    v-alert.py-3.mt-5(
      v-if="certificate.count === 0"
      color="primary"
      outlined
    ) В региональном навигаторе не содержится записи о ребенке с указанными Вами персональными данными.
      |  Проверьте, пожалуйста, правильность заполнения персональных данных.

    .d-flex.justify-end.flex-wrap.mt-2
      v-btn.mr-2(
        depressed
        @click="$emit('prev')"
      ) Назад
      v-btn(
        color="primary"
        depressed
        :loading="loading"
        @click="submit"
      ) Проверить

</template>

<script>
import PolicyCheckbox from '@/components/PolicyCheckbox';
import DatePicker from '@/components/DatePicker';

const OUR_AIS = 3;

export default {
  name: 'StepCertificateData',
  components: { DatePicker, PolicyCheckbox },
  props: {
    certificate: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },
  computed: {
    visibleDocument() {
      return OUR_AIS === this.certificate.region?.certificate_source;
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit');
      }
    },
  },
};
</script>
