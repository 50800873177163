<template lang="pug">
  .enrollment-item(
    :class="[{ 'cursor-pointer': haveFake }, checked ? 'checked--bg' : 'not-checked--bg']"
    @click="toggleCheck"
  )
    .d-flex.py-4.px-2.align-center.lesslight
      h3.title(:class="{ 'warning--text': checked }") {{ enrollmentDate }}
      v-btn.ml-auto(
        v-show="haveFake"
        depressed
        small
        :color="checked ? 'secondary' : 'primary'"
        :class="{ 'pulse': !inputValue.length }"
      )
        span.hidden-sm-and-down {{ checked ? 'снять отметку' : 'отметить' }}
        v-icon.hidden-md-and-up  {{ checked ? 'close' : 'check' }}

    ul.list
      li.item
        .label Наименование организации
        .value(@click.stop)
          a(
            :href="`https://${region.href}/app/the-navigator/organization/${enrollment.organization_id}`"
            target="_blank"
          ) {{ enrollment.organization_name }}

      li.item
        .label Наименование программы
        .value
          a(
            :href="`https://${region.href}/app/the-navigator/program/${enrollment.program_id}`"
            target="_blank"
          ) {{ enrollment.program_name }}

      li.item
        .label Число ак. часов занятий в неделю
        .value {{ halfRound(enrollment.weekly_hours) }}

      li.item(v-if="enrollment.responsible_person")
        .label Ответственное лицо
        .value {{ enrollment.responsible_person }}

</template>

<script>
import monthNumberToName from '@/utils/monthNumbersConversion';

export default {
  name: 'EnrollmentItem',
  props: {
    value: { type: Array, default: () => [] },
    enrollment: { type: Object, required: true },
    region: { type: Object, default: () => ({}) },
    haveFake: { type: Boolean, required: false },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    checked() {
      return this.inputValue.includes(this.enrollment.id);
    },
    enrollmentDate() {
      const start = this.replaceDateMonth(this.enrollment.date_start);
      const stop = this.replaceDateMonth(this.enrollment.date_stop);
      return `${start} - ${stop}`;
    },
  },
  methods: {
    toggleCheck() {
      if (this.haveFake) {
        if (this.checked) {
          this.inputValue = this.inputValue.filter((i) => i !== this.enrollment.id);
        } else {
          this.inputValue.push(this.enrollment.id);
        }
      }
    },
    halfRound(val) {
      return Math.round(val * 2) / 2;
    },
    replaceDateMonth(date) {
      if (!date) return '';
      date = date.split('.');
      date[0] = monthNumberToName(Number(date[0]));
      return date.join(' ');
    },
  },
};
</script>

<style scoped lang="scss">

  .v-btn {
    max-width: max-content;
  }

  .enrollment-item {
    border: 1px solid;
    border-radius: 4px;
    overflow: hidden;
  }

  .not-checked--bg {
    border-color: $lesslight-color;
  }

  .checked--bg {
    border-color: $secondary-color;
  }

  .pulse {
    animation: pulse 2s infinite;
  }

  .list {
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
    list-style: none;

    .item {
      padding: 8px;
      display: flex;

      .label {
        width: 40%;
        padding-right: 4px;
        display: block;
        font-size: 1.1rem;
        font-weight: bold;
        text-align: left;
        padding-left: 0;
        max-width: 800px;
        white-space: normal;
        line-height: 1.4rem;
      }

      .value {
        width: 60%;
        font-size: 1.1rem;

        a {
          font-size: inherit;
        }
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;

        .label, .value {
          width: 100%;
        }
      }
    }
  }

</style>
