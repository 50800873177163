<template lang="pug">
  div
    p.text-justify
      span.d-block.mb-1 Спасибо Вам за информацию о "липовых зачислениях"! К сожалению, то, с чем столкнулись Вы - далеко
        |  не исключение. К большому сожалению - правило. Причем использование данных детей для "очковтирательства"
        |  происходит далеко не по желанию самих организаций. Сегодня сама система управления, начиная с федерального
        |  министерства, просто заставляет всех "рисовать нужные цифры". Пока дело обстоит таким образом, не стоит
        |  рассчитывать на то, что чиновники вспомнят о том, что за цифрами - дети.
      br
      span Просим Вас ознакомиться с содержанием коллективного обращения в администрацию Президента России, принять для себя
        |  решение, готовы ли Вы подписаться под ним:

    v-alert(
      color="primary"
      icon="message"
      outlined
    )
      span Мы, нижеуказанные родители (законные представители) детей в возрасте от 5 до 18 лет, призываем Вас обратить внимание
        |  на фактическое положение дел в системе дополнительного образования России.
      span Мы приветствуем поставленную Президентом России в указе №599 от 7 мая 2012 года цель по обеспечению охвата
        |  дополнительным образованием детей в возрасте от 5 до 18 лет. При этом видим единственный путь достижения этой цели
        |  в действительном вовлечении детей в разнообразные кружки и секции.
      span Однако, вопреки нашим ожиданиям, в соответствии с которым государство будет нацелено на предоставление
        |  дополнительного образования нашим детям, каждый из нас фактически столкнулся с тем, что наши дети стали просто
        |  цифрами в отчетности чиновников.
      span Внедренная система персонифицированного учета, предусматривающая, что для отчета об охвате детей нужно персонально
        |  показать их как зачисленных на кружки/секции, позволила нам увидеть на каких кружках числятся наши дети.
        |  Оказалось, что для того, чтобы отчитаться об охвате, чиновники различного уровня повсеместно приняли решение не
        |  предлагать новые интересные программы, завлекая детей к себе, а просто незаконно использовать их персональные
        |  данные для внесения в систему-навигатор.
      span Считаем недопустимой ситуацию, когда, для того, чтобы отчитаться о выполнении указа Президента России, вместо того,
        |  чтобы его выполнить, предоставив детям возможности развиваться, организации самостоятельно записывают детей в те
        |  кружки и секции, о существовании которых не знают ни родители, ни сами дети.
      span Просим принять меры и изменить направление социальной политики, отдав предпочтение реальному охвату и образованию
        |  детей, а не рисованию красивой отчетной цифры.

    .d-flex.justify-end.mt-5.flex-wrap
      v-btn.mr-auto.mt-1(
        color="warning"
        depressed
        outlined
        @click="$emit('complete')"
      )
        span.hidden-sm-and-down.warning--text Не подпишусь, само наладится всё как-нибудь
        span.hidden-md-and-up.warning--text  Не подпишусь

      v-btn.mr-2.mt-1.order-first.order-sm-0(
        depressed
        @click="$emit('prev')"
      ) Назад
      v-btn.mt-1(
        color="success"
        depressed
        @click="$emit('subscribe')"
      ) Подписаться под обращением

</template>

<script>
export default {
  name: 'StepSubscribeText',
};
</script>

<style scoped>

  .v-alert__content span {
    display: block;
    margin-bottom: 8px;
    text-align: justify;
    font-weight: 600;
  }

</style>
