<template lang="pug">
  v-form(ref="form")
    v-layout(row)
      v-flex(sm12 md6)
        v-text-field(
          v-model="parent.soname"
          label="Фамилия*"
          :rules="[$rules.required]"
        )

      v-flex(sm12 md6)
        v-text-field(
          v-model="parent.name"
          label="Имя*"
          :rules="[$rules.required]"
        )

      v-flex(sm12 md6)
        v-text-field(
          v-model="parent.phname"
          label="Отчество (при наличии)"
        )

      v-flex(sm12 md6)
        v-text-field(
          v-model="parent.email"
          :rules="[$rules.required, $rules.email]"
          label="Ваша электронная почта*"
        )

    policy-checkbox(
      :rules="[$rules.required]"
    )

    .d-flex.justify-end.flex-wrap.mt-5
      v-btn.mr-2(
        depressed
        @click="$emit('prev')"
      ) Назад
      v-btn(
        color="success"
        depressed
        :loading="loading"
        @click="toSubscribe"
      ) Подписаться

</template>

<script>
import API from '@/services/check-enrollments';
import PolicyCheckbox from '@/components/PolicyCheckbox';

export default {
  name: 'StepParentData',
  components: { PolicyCheckbox },
  props: {
    token: { type: String, default: null },
    certificate: { type: Object, required: true },
  },
  data: () => ({
    parent: {},
    loading: false,
  }),
  methods: {
    toSubscribe() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const params = {
          confirm_token: this.token,
          yanao: Number(this.certificate?.region?.reg_id === 'YAN'),
          ...this.parent,
        };

        const request = this.certificate?.region?.reg_id === 'YAN' ? API.saveEnrollmentsYanao : API.saveEnrollments;

        this.$rHandler(
          request(params),
          () => { this.$emit('next'); },
        ).finally(() => { this.loading = false; });
      }
    },
  },
};
</script>
