<template lang="pug">
  div
    v-autocomplete(
      v-model="certificate.region"
      label="Выбор региона"
      :items="regions"
      item-text="title"
      return-object
      clearable
      :loading="loading"
      :rules="[$rules.required]"
    )

    v-alert(v-if="notEnable" type="warning" outlined)
      | К сожалению, информация о зачислениях в выбранном Вами регионе не доступна для осуществления необходимой проверки.
      |  Видимо, так всем спокойнее...

    v-alert(v-else-if="notOurRegion" type="warning" outlined)
      | К сожалению, информация о зачислениях в выбранном Вами регионе не доступна поскольку система,
      |  используемая для ведения реестра сертификатов, пока не подключена к функционалу проверки зачислений

    .d-flex.justify-end.mt-2
      v-btn(
        color="primary"
        depressed
        :disabled="nextDisabled"
        @click="selectRegion"
      ) Далее

</template>

<script>
import main from '@/services/main';

export default {
  name: 'StepSelectRegion',
  props: {
    certificate: { type: Object, required: true },
  },
  data: () => ({
    regions: [],
    loading: false,
  }),
  computed: {
    nextDisabled() {
      return this.certificate?.region?.certificate_source !== 3 || this.certificate?.region?.enabled === 0;
    },
    notOurRegion() {
      return (this.certificate?.region?.certificate_source !== 3 && this.certificate?.region?.enabled === 1) && this.certificate?.region;
    },
    notEnable() {
      return this.certificate?.region?.enabled === 0 && this.certificate?.region;
    },
  },
  created() {
    this.getRegions();
  },
  methods: {
    getRegions() {
      this.loading = true;
      this.$rHandler(
        main.getRegionsForMap({ expand: 'certificate_source' }),
        ({ data }) => {
          this.regions = data.data
            .map((region) => {
              if (region.reg_id === 'YAN') {
                region.operator_id = 1;
                region.certificate_source = 3;
              } else if (region.reg_id === 'VOR') {
                // region.certificate_source = null;
              }
              return region;
            })
            .sort((a, b) => a.title > b.title ? 1 : -1);
        },
      ).finally(() => { this.loading = false; });
    },
    selectRegion() {
      this.$emit('next');
    },
  },
};
</script>
